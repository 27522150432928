import posthog from 'posthog-js'

posthog.init(process.env.MIX_POSTHOG_API_KEY, {
    api_host: process.env.MIX_POSTHOG_HOST,
    person_profiles: 'always' // or 'identified_only'
})

window.posthog = posthog

function sendProductAnalyticEvent(event_name, properties=null){
    posthog.capture(event_name, properties)
}

window.sendProductAnalyticEvent = sendProductAnalyticEvent

const productAnalyticEvents = {
    user_visit_homepage: 'user_visit_homepage',
    user_click_service_in_home_navbar: 'user_click_service_in_home_navbar',
    user_click_button_find_inspiration_in_jumbotron: 'user_click_button_find_inspiration_in_jumbotron',
    user_click_button_custom_design_in_jumbotron: 'user_click_button_custom_design_in_jumbotron',
    user_click_card_order_garden_in_our_services: 'user_click_card_order_garden_in_our_services',
    user_click_card_order_design_in_our_services: 'user_click_card_order_design_in_our_services',
    user_click_card_plants_and_materials_in_our_services: 'user_click_card_plants_and_materials_in_our_services',
    user_click_card_garden_care_in_our_services: 'user_click_card_garden_care_in_our_services',
    user_click_button_order_design_in_encyclopedia: 'user_click_button_order_design_in_encyclopedia',
    user_click_button_order_garden_in_encyclopedia: 'user_click_button_order_garden_in_encyclopedia',
    user_click_button_order_plant_in_encyclopedia: 'user_click_button_order_plant_in_encyclopedia',
    user_click_button_find_inspiration_in_order_design_form: 'user_click_button_find_inspiration_in_order_design_form',
    user_click_button_custom_inspiration_in_order_design_form: 'user_click_button_custom_inspiration_in_order_design_form',
    user_click_button_no_inspiration_in_order_design_form: 'user_click_button_no_inspiration_in_order_design_form',
    user_click_button_add_in_find_inspiration_in_order_design_form: 'user_click_button_add_in_find_inspiration_in_order_design_form',
    user_click_button_add_in_custom_inspiration_in_order_design_form: 'user_click_button_add_in_custom_inspiration_in_order_design_form',
    user_click_button_upload_in_area_photos_in_order_design_form: 'user_click_button_upload_in_area_photos_in_order_design_form',
    user_click_link_refund_terms_and_condition_in_order_design_form: 'user_click_link_refund_terms_and_condition_in_order_design_form',
    user_click_button_next_in_order_design_form: 'user_click_button_next_in_order_design_form',
    user_click_button_order_garden_in_encyclopedia_garden: 'user_click_button_order_garden_in_encyclopedia_garden',
    user_click_button_create_garden_in_my_order: 'user_click_button_create_garden_in_my_order',
    user_visit_create_garden_page: 'user_visit_create_garden_page',
    user_visit_order_garden_care_form_after_selecting_no_promo: 'user_visit_order_garden_care_form_after_selecting_no_promo',
    user_click_garden_care_schedule_on_calender_in_garden_care_form: 'user_click_garden_care_schedule_on_calender_in_garden_care_form',
    user_click_button_add_product: 'user_click_button_add_product',
    user_click_button_reschedule_on_menu_garden_care_details_in_detail_page: 'user_click_button_reschedule_on_menu_garden_care_details_in_detail_page',
    user_click_button_schedule_on_menu_garden_care_details_in_detail_page: 'user_click_button_schedule_on_menu_garden_care_details_in_detail_page',
    user_click_schedule_on_menu_result_in_detail_page: 'user_click_schedule_on_menu_result_in_detail_page',
};

window.productAnalyticEvents = productAnalyticEvents
